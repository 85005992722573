<template>
  <div class="links-widget">
    <div class="links-widget__header">
      <span
        v-if="!linksAllItems.length && !linksIds.length"
        @click="isModal = true"
        class="links-widget__upload"
        >Прикрепить ссылку</span
      >
      <span v-if="linksAllItems.length || linksIds.length" class="links-widget__label">Ссылки</span>
      <span
        v-if="linksAllItems.length || linksIds.length"
        @click="clearPool"
        class="links-widget__clear"
        >Очистить</span
      >
    </div>
    <div v-if="linksAllItems.length || linksIds.length" class="links-widget__items">
      <div
        v-for="(linkItem, idx) in linksAllItems"
        :key="idx + '_' + linkItem.link"
        class="links-widget__item"
      >
        <i class="icon-chain"></i>
        {{ cutterText(linkItem.label) }}
        <i class="icon-cross" @click="cutLinksArr(idx, 1)"></i>
      </div>
      <div @click="isModal = true" class="links-widget__add-more">
        <i class="icon-plus"></i>
        <span>Добавить еще</span>
      </div>
    </div>
    <div :class="['links-widget__modal', isModal ? 'links-widget__modal_opened' : '']">
      <div class="links-widget__modal__header">
        <h3>Добавить ссылку</h3>
        <i class="icon-cross" @click="closeModal"></i>
      </div>
      <Widget
        type="string"
        label="URL"
        placeholder="http://"
        :unique="'link-add-edit-regulation'"
      />
      <Widget
        type="string"
        label="Название"
        :maxlength="50"
        placeholder="Введите название ссылки"
        :unique="'link-add-name-edit-regulation'"
      />
      <div class="links-widget__buttons">
        <Widget
          type="Button"
          :outlined="true"
          label="Отменить"
          :unique="'regulation-link-cancel-edit'"
        />
        <Widget type="Button" label="Добавить" :unique="'regulation-link-edit'" />
      </div>
    </div>
  </div>
</template>

<script>
import httpClient from "@/api/client/httpClient";

const Widget = () => import("@/components/Widgets/Widget");

import { domain } from "@/globalVariables";

export default {
  name: "LinksEditWidget",
  mixins: [],
  props: {
    unique: {
      type: [String, Number]
    },
    attachedLinks: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  components: { Widget },
  data() {
    return {
      loader: false,
      isModal: false,
      linkUrl: "",
      linkName: "",
      linksAllItems: [],
      linksIds: []
    };
  },
  beforeDestroy() {
    this.$root.$off("button:click_regulation-link-cancel-edit");
    this.$root.$off("button:click_regulation-link-edit");
  },
  mounted() {
    if (this.attachedLinks) {
      this.linksAllItems = this.attachedLinks;
      this.linksIds = this.linksAllItems.map((i) => {
        return i.id;
      });
    }
    this.$root.$on("text:change_link-add-edit-regulation", (value) => {
      this.linkUrl = value.trim();
    });
    this.$root.$on("text:change_link-add-name-edit-regulation", (value) => {
      this.linkName = value.trim();
    });
    this.$root.$on("button:click_regulation-link-cancel-edit", () => {
      this.closeModal();
    });
    this.$root.$on("button:click_regulation-link-edit", () => {
      this.addLink();
    });
  },
  methods: {
    clearPool() {
      this.linksAllItems.splice(0);
      this.linksIds.splice(0);
    },
    cutLinksArr(from, to) {
      this.linksAllItems.splice(from, to);
      this.linksIds.splice(from, to);
    },
    linkApi() {
      httpClient({
        url: `/AttachmentFile/create`,
        method: "POST",
        data: [
          {
            type: "link",
            label: this.linkName,
            link: this.linkUrl
          }
        ]
      })
        .then((response) => {
          this.linksIds.push(response.data.data.id);
          this.linksAllItems.push({ link: this.linkUrl, label: this.linkName });
          this.linkUrl = "";
          this.linkName = "";
          this.$root.$emit("input-text:clear_link-add-edit-regulation");
          this.$root.$emit("input-text:clear_link-add-name-edit-regulation");
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    },
    addLink() {
      if (!this.linkUrl.length || !this.linkName.length) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Все поля должны быть заполнены!`
        });
        return false;
      } else if (this.linksIds.length >= 10) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Количество ссылок не должно превышать 10!`
        });
        return false;
      } else if (this.linkName.length > 50) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Количество символов в названии ссылки не должно превышать 50!`
        });
        return false;
      } else if (
        !this.linkUrl.match(
          /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)/
        )
      ) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Введите ссылку в формате: https(s)://sitename.domen`
        });
        return false;
      } else {
        this.linkApi();
      }
    },
    closeModal() {
      this.isModal = false;
      this.linkUrl = "";
      this.linkName = "";
      this.$root.$emit("input-text:clear_link-add-create-regulation");
      this.$root.$emit("input-text:clear_link-add-name-create-regulation");
    },
    cutterText(text) {
      if (text.length <= 10) {
        return text;
      } else return text.substr(0, 10) + "...";
    }
  },
  computed: {},
  watch: {
    linksIds() {
      this.$root.$emit(`links:change_${this.unique}`, this.linksIds);
    }
  },
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.links-widget {
  width: 100%;
  position: relative;

  &__upload {
    font-weight: 400;
    font-size: 14px;
    line-height: 27px;
    color: $text-subdued;

    cursor: pointer;
    transition: all 0.2s ease;

    @media (hover: hover) {
      &:hover {
        color: $text-default;
      }
    }

    &:active {
      color: $text-default;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    i {
      font-size: 20px;
      color: $icon-subdued;
      margin-right: 8px;
    }
  }

  &__label {
    font-size: 14px;
    line-height: 20px;
    color: $text-subdued;
    font-weight: 400;
  }

  &__clear {
    margin-left: auto;
    color: $text-default;
    padding: 4px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;

    cursor: pointer;

    &:hover {
      background: $action-secondary-hovered;
      border-radius: 4px;
    }
  }

  &__items {
    display: flex;
    align-items: center;

    flex-wrap: wrap;
  }

  &__item {
    display: flex;
    align-items: center;
    margin-right: 7px;
    margin-bottom: 7px;
    padding-left: 4px;
    padding-right: 7px;
    width: 128px;
    height: 28px;

    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    color: $text-default;

    border-radius: 4px;
    background-color: $action-secondary-hovered;
    user-select: none;

    .icon-chain {
      color: $icon-subdued;
      font-size: 18px;
      line-height: 20px;
      margin-right: 5px;
      margin-bottom: 2px;
    }

    .icon-cross {
      flex-shrink: 0;
      margin-left: auto;
      cursor: pointer;
    }
  }

  &__add-more {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 7px;
    padding-left: 4px;
    padding-right: 7px;
    width: 128px;
    height: 28px;

    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    color: $text-default;

    border-radius: 4px;
    background-color: $action-secondary-hovered;
    user-select: none;
    cursor: pointer;
    transition: all 0.2s ease;

    .icon-plus {
      margin-right: 5px;
      font-size: 18px;
    }

    @media (hover: hover) {
      &:hover {
        background-color: $action-secondary-pressed;
      }
    }

    &:active {
      background-color: $action-secondary-pressed;
    }
  }

  &__modal {
    z-index: 20;
    position: absolute;
    bottom: 100%;
    left: 45%;
    width: 100%;
    padding: 20px 21px;

    background-color: $color-white;
    box-shadow: 0 8px 15px 0 #ececf9;
    border: 1px solid $color-contour;
    border-radius: 10px;
    transition: all 0.2s ease;
    transform: translateX(-50%);
    display: none;
    opacity: 0;

    @media screen and (max-width: $sm) {
      height: 320px;
    }

    @media screen and (max-width: 420px) {
      width: 250px;
    }

    &_opened {
      display: block;
      opacity: 1;
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 22px;

      h3 {
        margin: 0;
        color: $color-text-accent;
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
      }

      .icon-cross {
        transition: 0.2s ease;
        cursor: pointer;
        color: $color-text-secondary;
        font-size: 20px;

        @media (hover: hover) {
          &:hover {
            color: $color-button-hover;
          }
        }

        &:active {
          color: $color-button-click;
        }
      }
    }

    /deep/ {
      .form-group-input-text {
        margin-bottom: 22px;

        &:last-child {
          margin-bottom: 30px;
        }
      }
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    @media screen and (max-width: $sm) {
      width: 100%;
    }

    /deep/ {
      .button-widget,
      button {
        @media screen and (max-width: $sm) {
          width: 100%;
        }
      }

      .button-widget {
        @media screen and (max-width: $sm) {
          order: 1;
        }

        &:first-child {
          margin-right: 15px;

          @media screen and (max-width: $sm) {
            order: 2;
            margin: 10px 0 0;
          }
        }
      }
    }
  }
}
</style>
